import React from 'react';
import './_backgrounds.scss';

export default class Backgrounds extends React.Component {
  render() {
    return (
      <div id="background">
        <div id="space">
          <span className="fadeIn" id="space-planet" />
          <span className="fadeIn" id="space-background" />
        </div>
      </div>
    );
  }
}
