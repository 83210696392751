export const index = 'home';
export const TITLE_APPEND = ' - OByte';
export const TITLE_ADD = 'OByte';
export const OBYTE_HOST_UTIL = 'https://tools.obyte.it/';

export const PUBLIC_GRAPHICS_IMAGES = OBYTE_HOST_UTIL + 'public/graphics/images/';
export const GALLERY_ITEMSPERPAGE = 15;
// export const COMMENTS_ITEMSPERPAGE = 10;
export const PUBLIC_MODELS_IMAGES_3D = OBYTE_HOST_UTIL + 'public/models/images/';
export const PUBLIC_SLIDERS_IMAGES = OBYTE_HOST_UTIL + 'public/sliders/';
export const PUBLIC_TOOLS_IMAGES = OBYTE_HOST_UTIL + 'public/tools/images/';
export const GALLERY_FILE_EXT = '.jpg';
export const GALLERY_MIN_FILE_EXT = '.min' + GALLERY_FILE_EXT;
export const GALLERY_MODELS_TYPES_DEFAULT = { 'generic': 'cube' }; // generic
export const GALLERY_MODELS_TYPES = {
  'buildings': 'vihara', // torii-gate
  'characters': 'user-astronaut', // 'user-shield', // 'male', 'walking', // 'child',
  'weapons': 'crosshairs',
  'animals': 'paw',
  'objects': 'chess-pawn', // dice-d6
  'monsters': 'dragon',
  'vehicles': 'truck-monster',
};
export const GALLERY_TOOLS_TYPES = ['tools', 'games', 'beta'];
export const GALLERY_GRAPHICS_TYPES = {
  'render': 'dice-d6',
  'video': 'video',
  'other': 'map',
};
export const DEFAULT_LANG = 'en';
export const PORTALS_LANGUAGES = ['en', 'it', 'es'];
export const PORTALS_LANG_SWITCH = OBYTE_HOST_UTIL + 'portals/lang/idrobyte/';
export const EVENTS = 'carnival'; // halloween christmas easter carnival

export const PORTALS_CONDITIONS = OBYTE_HOST_UTIL + 'public/docs/condizioniUtilizzo_'; // 'en.html';

/**** USERS ****/
export const AUTH_HEADER = 'Authorization';
export const AUTH_PROTECTED_METHODS = ['passwordModify', 'getModelsItem', 'sendLike', 'getProfile', 'addWebSite'];
export const REG_WEBSITE_REGEX = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
