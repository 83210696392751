import React from 'react';
import './_footer.scss';
import * as Config from '../../../Config';

export default class Footer extends React.Component {
  render() {
    return (
      <footer id="footer" className="fadeIn def_back">
        <span>Copyright &copy; 2020-{new Date().getFullYear()} sandbay.it. All rights reserved.&nbsp;
          <a id="footer-privacy-link" target="_blank" rel="noopener noreferrer"
             href={`${Config.OBYTE_HOST_UTIL}public/docs/privacy_policy_en.html`}>
            Privacy
          </a>
          <br/>Optimized for all the major browsers.
        </span>
      </footer>
    );
  }
}
