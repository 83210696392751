import React from 'react';
import { TranslateComponent } from 'react-translate-json/react';
import './_menu.scss';
import * as Config from '../../../Config';

export default class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fixed: props.fixed,
      navOpen: false
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) { // To re-render on parent update
    if(nextProps.fixed !== prevState.fixed) {
      return {fixed: nextProps.fixed};
    }
    else return null;
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.fixed !== this.props.fixed) {
      this.setState({fixed: this.props.fixed});
    }
  }
  toggleClass() {
    this.setState({ navOpen: !this.state.navOpen });
  };

  render() {
    return (
      <div>
        <div id="mob-menu-back" className={`fadeIn mobile ${this.state.navOpen ? 'navOpen' : ''}`}
             onClick={this.toggleClass.bind(this)} />
        <nav id="menu" className={`navbar navbar-default ${this.state.fixed ? 'fixed' : ''}`} role="navigation">
          <div className="menu-toggle mobile" onClick={this.toggleClass.bind(this)}>
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </div>
          <ul id="main-menu" className={`${this.state.navOpen ? 'navOpen' : ''}`}>
            <li className="menu-item"><a href="/home">
                <span className="menu-link sprite_world double-line">
                  <i className="fas fa-home" aria-hidden="true" /><br />
                  <TranslateComponent label="menu.sezHome" />
                </span>
            </a></li>
            <li className="menu-item">
              <a href="/gallery-models">
                  <span className="menu-link sprite_world double-line">
                    <i className="fas fa-cube" aria-hidden="true" /><br />
                    <TranslateComponent label="menu.sez3dModelsDouble" />
                  </span>
              </a>
              <div className="overvocem sprite_world" />
              <ul className="toggleMenu toggleMenuWidth">
                {Object.keys(Config.GALLERY_MODELS_TYPES).map((k, v) => {
                  return (
                      <li key={k} className="toggleItem">
                        <a className={k} href={`/gallery-models/${k}`}>
                          <i className={`fas fa-${Config.GALLERY_MODELS_TYPES[k]}`} aria-hidden="true" />
                          {` ${k}`}
                        </a>
                      </li>
                  );
                })
                }
              </ul>
            </li>
            <li className="menu-item"><a href="/gallery-websites">
                <span className="menu-link sprite_world double-line">
                  <i className="fas fa-globe" aria-hidden="true" /><br />
                  <TranslateComponent label="menu.sezWebsites" />
                </span>
            </a></li>
            <li className="menu-item"><a href="/gallery-graphics">
                <span className="menu-link sprite_world double-line">
                  <i className="fas fa-map" aria-hidden="true" /><br />
                  <TranslateComponent label="menu.sezGraphics" />
                </span>
            </a></li>
            <li className="menu-item"><a href="/gallery-tools">
                <span className="menu-link sprite_world double-line">
                  <i className="fas fa-wrench" aria-hidden="true" /><br />
                  <TranslateComponent label="menu.sezTools" />
                </span>
            </a></li>
            <li className="menu-item">
              <a href="/about">
                  <span className="menu-link sprite_world double-line">
                    <i className="fas fa-users" aria-hidden="true" /><br />
                    <TranslateComponent label="menu.sezChiSiamo" />
                  </span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}
