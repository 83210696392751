import React from 'react';
import './_header.scss';
import Menu from '../menu/Menu';
import logo from '../../../../assets/logo.svg';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      handleScroll: (event) => {
        if (!this.state.fixedClass || (window.scrollY === 0)) {
          this.setState({
            fixedClass: (window.scrollY !== 0)
          });
        }
      },
      fixedClass: false
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.state.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.state.handleScroll, false);
  }

  render() {
    return (
      <header id="header" role="banner" className={`fadeIn def_back ${this.state.fixedClass ? 'fixed' : ''}`}>
        <div id="logo-full">
          <h1 id="logo">
            <img src={logo} className="react-logo" alt="logo" />
            <a href="/" className="sprite_world text-hide" id="OByte" rel="OByte">OByte</a>
          </h1>
          <h2 id="motto">React version</h2>
        </div>
        <Menu fixed={this.state.fixedClass} />
      </header>
    );
  }
}
