import React from 'react';

export default class Loading extends React.Component {
  render() {
    return (
      <div className="lds-css ng-scope">
        <div className="lds-eclipse">
          <div> </div>
        </div>
      </div>
    );
  }
}
