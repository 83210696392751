import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  // Link,
  Redirect } from 'react-router-dom';
import { TranslateProvider } from 'react-translate-json';

import Header from './components/common/header/Header';
import Footer from './components/common/footer/Footer';
import Backgrounds from './components/common/backgrounds/Backgrounds';
import Loading from './components/common/loading/Loading';

/** PAGES */
const Home = lazy(() => import('./components/home/Home'));
const GalleryModels = lazy(() => import('./components/gallery-models/GalleryModels'));
const GalleryGraphics = lazy(() => import('./components/gallery-graphics/GalleryGraphics'));
const GalleryTools = lazy(() => import('./components/gallery-tools/GalleryTools'));
const GalleryWebsites = lazy(() => import('./components/gallery-websites/GalleryWebsites'));
const About = lazy(() => import('./components/about/About'));
const Error = lazy(() => import('./components/error/Error'));

const translationOptions = {
  pathPrefix: 'https://tools.obyte.it/portals/lang/idrobyte/', // Path to your translations
  locale: 'en', // User current locale
  fallbackLocale: 'en' // Fallback locale
};

const App = () => (
  <TranslateProvider {...translationOptions}>
    <div className="App">
      <Header />
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/" render={() => (<Redirect to="/home" />)} />
            <Route path="/home" component={Home} />
            <Route path="/gallery-models/:category?" component={GalleryModels} />
            <Route path="/gallery-graphics" component={GalleryGraphics} />
            <Route path="/gallery-tools" component={GalleryTools} />
            <Route path="/gallery-websites" component={GalleryWebsites} />
            <Route path="/about" component={About} />
            <Route path="*" component={Error} />
          </Switch>
        </Suspense>
      </BrowserRouter>
      <Footer />
      <Backgrounds />
    </div>
  </TranslateProvider>
);

export default App;
